<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { authUserMethods } from "../../../state/helpers/authuser"
import {
  required,
} from "vuelidate/lib/validators";
import { faqsMethods } from "../../../state/helpers/faqs";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
/**
 * Blank page component
 */
export default {
  page: {
    title: "FAQs",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, ckeditor: CKEditor.component  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbarLocation: "bottom",
      },
      is_sort_asc: true,
      is_sort_ascdesc_enable: false,
      active_column: '',
      faq: {
        question: '',
        answer: '',
      },
      faqs: [],
      title: "FAQs",
      is_update: false,
      submitted: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      page: 1,
      fields: [
        { key: "question", sortable: true,},
        {key: "answer"}
      ]
    };
  },
  validations: {
    faq: {
      question: { required },
      answer: { required },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
  },
  mounted() {
    this.getFAQs()
    // Set the initial number of items
    // this.totalRows = this.items?.length;
  },
  methods: {
    ...authUserMethods,
    ...faqsMethods,

    getFAQs() {
      return this.allFaqs({ paginate: true, page: this.page,  order_field: 'question', ranking: 'asc'.toUpperCase() }).then((response) => {
        // eslint-disable-next-line no-console
        this.faqs = response.data.data
        this.totalRows = response.data.total

      })
    },
    handlePageChange(page) {
      const field = this.active_column
      let query;
      if(field != '') {
        query =  { paginate: true, page, order_field: field, ranking: this.is_sort_asc ? 'asc'.toUpperCase() : 'desc'.toUpperCase() }
      }else{
        query = { paginate: true, page, order_field: 'question', ranking: 'asc'.toUpperCase() }
      }
      return this.allFaqs(query).then((response) => {
        // eslint-disable-next-line no-console
        this.faqs = response.data.data
        this.totalRows = response.data.total

      })
    },
    searchData(event) {
      // eslint-disable-next-line no-console
      const value = event.target.value
      return this.allFaqs({ paginate: true, keyword: value, page: this.page, order_field: 'question', ranking: 'asc'.toUpperCase() }).then((response) => {
        // eslint-disable-next-line no-console
        this.faqs = response.data.data
        this.totalRows = response.data.total
      })
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.$bvModal.hide('app-modal')
      this.is_update = false;
      this.emptyData()
    },
    showDetails(data) {
      this.faq = data
      this.is_update = true;
      this.showModal()
    },
    handleSaveFaq() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {

        this.createFaqs(this.faq).then(() => {
          this.hide()
          this.getFAQs()
        })
      }
      this.submitted = false;
    },
    handleUpdateFaqsDetails() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {

        this.updateFaqs(this.faq).then(() => {
          this.hide()
          this.getFAQs()
        })
      }
      this.submitted = false;
    },
    emptyData() {
      this.faq = {
        question: "",
        answer: ""
      }
    },
    deleteFaqItem() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.deleteFaqs(this.faq.id).then(() => {
            this.getFAQs()
            this.hide()
          })
        }
      });
    },
    async handleTableHeadClicked(item){
      this.active_column = item
      this.is_sort_asc = !this.is_sort_asc
      let query = { paginate: true, page: this.page, order_field: item, ranking: this.is_sort_asc ? 'asc'.toUpperCase() : 'desc'.toUpperCase() }
      await this.allFaqs(query).then((response) => {
        // eslint-disable-next-line no-console
        this.faqs = response.data.data
        this.totalRows = response.data.total
      })
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-md-6">
        <b-card no-body class="mt-5">
          <b-card-body>
            <b-card-title class="d-flex mb-3 align-items-center justify-content-between">
              <h1 class="card-title">FAQs List</h1>
              <a href="javascript:void(0);" class="btn btn-outline-success btn-block" @click="showModal">Add New</a>
            </b-card-title>
            <b-card-title class="d-flex mb-3 align-items-center justify-content-between">
              <div class="row" style="flex: 1">
                <div class="col-md-8">
                  <label class="form-label" for="validationCustom04">Search for FAQs</label>
                  <input id="validationCustom04" type="text" class="form-control" @input="searchData" />
                </div>
              </div>
            </b-card-title>
            <b-card-text>
              <!-- Table -->
              <div class="table-responsive mb-0 datatables account_table">
                <b-table :items="faqs" :fields="fields"   responsive="sm"
          @row-clicked="showDetails"
          id="app-table" striped="striped"
          borderless="borderless" outlined="outlined" fixed="fixed" sticky-header="true"
          @head-clicked="handleTableHeadClicked">
          <template #head()="data">
                        <div class="table_heading_view">
                          <span class="">{{ data.label }}</span>
                          <i class="fas fa-sort-amount-down-alt  text-success" id="sort-2-tooltip"
                            v-if="is_sort_asc && data.column === active_column"></i>
                          <i class="fas fa-sort-amount-up  text-info" id="sort-2-tooltip"
                            v-if="!is_sort_asc && data.column === active_column"></i>
                        </div>

                      </template>
                      <template #cell(answer)="data">
                    <div v-html="data.value" />
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-end">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                        @change="handlePageChange"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-card-text>



            <!-- user modal -->
            <b-modal id="app-modal" :title="is_update ? 'Update FAQs Details' : 'Create New FAQs'" title-class="font-18"
              size="lg" hide-footer @hide="hide">
              <div class="row">

                <div class="col-md-12">
                  <!-- <h4 class="mb-3 mt-3">Infomation</h4> -->
                  <form class="needs-validation">
                    <div class="row">

                      <div class="col-md-12">
                        <div class="mb-4">
                          <label class="form-label" for="validationCustom04">Question</label>
                          <input id="validationCustom04" v-model="faq.question" type="text" class="form-control"
                            placeholder="" :class="{
                              'is-invalid': submitted && $v.faq.question.$error,
                            }" />
                          <div v-if="submitted && $v.faq.question.$error" class="invalid-feedback">
                            <span v-if="!$v.faq.question.required">This value is required.</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="mb-4">
                          <label class="form-label" for="validationCustom04">Answer</label>
                          <ckeditor id="validationCustom04"  v-model="faq.answer" :config="editorConfig" :editor="editor"
                          :class="{
                            'is-invalid': submitted && $v.faq.answer.$error,
                          }"
                          ></ckeditor>

                          <!-- <textarea id="validationCustom04" style="min-height: 250px;" v-model="faq.answer" class="form-control" :class="{
                            'is-invalid': submitted && $v.faq.answer.$error,
                          }" rows="10" cols="10" placeholder="Add answer content here..."></textarea> -->

                          <div v-if="submitted && $v.faq.answer.$error" class="invalid-feedback">
                            <span v-if="!$v.faq.answer.required">This value is required.</span>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="button_view d-flex align-items-center justify-content-between mt-4"
                    >
                      <button type="button" class="btn btn-outline-secondary" @click="hide">Cacel</button>
                      <button type="button" v-if="is_update" class="btn btn-outline-danger"
                        @click="deleteFaqItem">Delete</button>
                      <button type="button" class="btn btn-outline-success"
                        @click="is_update ? handleUpdateFaqsDetails() : handleSaveFaq()">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </b-modal>

          </b-card-body>
        </b-card>
      </div>
    </div>

  </Layout>
</template>