import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const faqsComputed = {
    ...mapState('faqs', {
        faqs:(state) => state.faqs,
    }),
    ...mapGetters('faqs', ['getFaqs'])
}

// export property actions
export const faqsMethods = mapActions('faqs', ['allFaqs','createFaqs',
 'updateFaqs', 'deleteFaqs',
])
